import { faQuoteRight, faScaleBalanced, faUsersBetweenLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

const AcademicPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Academic Policy | Al-Maghrib';
    }, [])
    return (
        <div>
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/free-photo/top-view-wooden-pawns-arrangement_23-2149382447.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Academic Policies
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        Policies - Rules and Regulations
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>
            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">
                    <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            <div className="px-4 py-5 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                    <FontAwesomeIcon icon={faScaleBalanced} size="lg" />
                                </div>
                                <h6 className="text-xl font-semibold">Academic Disciplines</h6>

                                <p className="mt-2 mb-4 text-gray-600 text-justify">
                                    •	Students have to wear complete school uniform during school hours. <br />
                                    •	They must not be absent without permission. <br />
                                    •	The behavior of the students with peers, teachers, guardians and employees must be gentle and polite. <br />
                                    •	Every student must carry his/her ID card. <br />
                                    •	The student must attend at least 90% classes each session. <br />
                                    •	Every student must carry his/her books and copies according to the class routine.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            <div className="px-4 py-5 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                    <FontAwesomeIcon icon={faUsersBetweenLines} size="lg" />
                                </div>
                                <h6 className="text-xl font-semibold">For the Respected Parents</h6>

                                <p className="mt-2 mb-4 text-gray-600 text-justify">
                                    •	The guardians will have to be informed of the rules of regulations of the institute. <br />
                                    •	They must have knowledge about the procedure of Hifz. <br />
                                    •	Should they notice any problem, the Head of the Institute should be informed. <br />
                                    •	They will help the students comply with the rules of the institution. <br />
                                    •	In case of violation of any rules of the school, they will accept the decision coming from the authority. <br />
                                    •	Female guardians are requested to come in Hijab and Niqab following the Islamic dress code for them. <br />
                                    •	An Islamic ambience is to be ensured at home for the learners. <br />
                                    •	They should discuss the progress of their children with the head of the school and the faculty. <br />
                                    •	Students have to be dropped at and picked from the school on time. <br />
                                    •	They will have to attend different events and programs arranged by the school. <br />
                                    •	They have to pay the dues as per instructions. <br />
                                    •	They have to get along well with the officials and faculty alike. <br />

                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="lg:pt-12 pt-6 w-full md:w-12/12  text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            <div className="px-4 py-5 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                    <FontAwesomeIcon icon={faQuoteRight} size="lg" />
                                </div>
                                <h6 className="text-xl font-semibold">School's Rights</h6>

                                <p className="mt-2 mb-4 text-gray-600 text-justify">
                                    At Al-Maghrib International School, we firmly uphold the authority to adapt and amend our rules, regulations,
                                    and academic structures as deemed necessary.
                                    Our commitment to providing the best education entails continuous improvement, and we reserve the
                                    right to make adjustments that benefit our students and the learning environment.
                                    All properties, resources, and assets within the school premises fall under the exclusive authority of the school. We remain dedicated to maintaining a safe, productive, and enriching educational experience for all our students.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AcademicPolicy;