import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import Meta from '../../meta/Meta';
import { Helmet } from 'react-helmet-async';

const Vision = () => {
    const pageDescription = "To be the best Premium and Transparent Islamic school that represents a holistic environment that nurtures excellence in academic and moral education.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/about/vision";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Vision | Al-Maghrib';
    }, []);
    return (
        <>
            {/* <Meta pageTitle={"Vision | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} /> */}
            <Helmet>
                {/* Common meta tags */}
                <title>Vision | Al-Maghrib</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph meta tags for Facebook and other platforms */}
                <meta property="og:title" content="Vision | Al-Maghrib" />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {/* <meta name="twitter:site" content={twitterUsername} /> */}
                <meta name="twitter:title" content="Vision | Al-Maghrib" />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />

                {/* Additional meta tags for various platforms */}
                <meta property="al:web:url" content={pageUrl} />
            </Helmet>


            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://thumbs.dreamstime.com/z/our-vision-text-uppercase-letters-inscribed-small-cubes-arranged-crossword-fashion-common-letter-o-white-background-43164938.jpg?w=768')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Our Vision
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>
                                            To be the best Premium and Transparent Islamic school that represents a holistic and caring environment that
                                            nurtures excellence in academic and moral education. We aim to create successful learners, Responsible Muslim
                                            Citizens, Confident Individuals and accountable leaders that will serve humanity. We also believe in character
                                            before brilliance and every child is unique and every child is capable of learning.

                                        </strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                        <FontAwesomeIcon icon={faStar} size="lg" />
                                    </div>
                                    <h6 className="text-xl font-semibold">Our Vision</h6>
                                    <p></p>
                                    <p className="mt-2 mb-4 text-gray-600 ">
                                        To be the best Premium and Transparent Islamic school that represents a holistic and caring environment that
                                        nurtures excellence in academic and moral education. We aim to create successful learners, Responsible Muslim
                                        Citizens, Confident Individuals and accountable leaders that will serve humanity. We also believe in character
                                        before brilliance and every child is unique and every child is capable of learning.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Vision;