import React, { useEffect } from 'react';
import progress_pic_1 from '../../../assets/img/celebration_of_1_year/6.jpg'
import progress_pic_2 from '../../../assets/img/celebration_of_1_year/1.jpg'
import progress_pic_3 from '../../../assets/img/celebration_of_1_year/2.jpg'
import progress_pic_4 from '../../../assets/img/celebration_of_1_year/3.jpg'
import progress_pic_5 from '../../../assets/img/celebration_of_1_year/4.jpg'
import progress_pic_6 from '../../../assets/img/celebration_of_1_year/5.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward } from '@fortawesome/free-solid-svg-icons';

const Progress = () => {
    useEffect(() => {
        document.title = "Progress | Al-Maghrib";
    }, []);
    return (
        <div>
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/free-vector/anniversary-celebration-with-golden-balloons_1361-3246.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=ais')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        One Year of Al-Maghrib
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        We are celebrating your child's incredible journey of growth and achievement in their first year at Al-Maghrib International School. Find out the highlights, milestones, and a glimpse of the exceptional progress made by the children.
                                        Discover the transformative experience they've had during their time with us.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">
                    <div className="lg:pt-12 pt-6 w-full md:w-12/12 text-center">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            <div className="px-4 py-5 flex-auto">
                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                    <FontAwesomeIcon icon={faAward} size="lg" />
                                </div>
                                <h6 className="text-xl font-semibold">Celebrating ONE Year</h6>
                                <p></p>
                                <p className="text-justify mt-2 mb-4 text-gray-600 ">
                                    Alhamdulillah. Thanks to Allah (SWT) for making it happen.
                                    Celebrating an incredible milestone of Al Maghrib International School.
                                    This was a year of resilience, dedication, and unwavering teamwork!
                                    This journey has been filled with challenges that we conquered together Alhamdulillah, forming unbreakable bonds with our amazing students.
                                    Alhamdulillah successfully completed a year & if Allah will many more years to go in sha Allah.
                                    As we step into the future, may Allah bless us with an even more beautiful and smoother path ahead. <br /><br />
                                    The Al-Maghrib Family cordially invites you to visit our campus and exchange thoughts to shape our next generation.
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* gallery */}
                    <div class=" mx-auto px-5 py-2 lg:px-32 ">
                        <div class=" flex flex-wrap md:-m-2">
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_1}
                                />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_2}
                                />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_3}
                                />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_4}
                                />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_5}
                                />
                            </div>
                            <div className="w-1/2 p-1 md:p-2">
                                <img
                                    alt="gallery"
                                    class="block h-full w-full rounded-lg object-cover object-center"
                                    src={progress_pic_6}
                                />
                            </div>

                        </div>
                    </div>

                    {/* gallery 2 */}
                    {/* <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
                        <div class="grid gap-4">
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-1.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-2.jpg" alt="" />
                            </div>
                        </div>
                        <div class="grid gap-4">
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-3.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-4.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-5.jpg" alt="" />
                            </div>
                        </div>
                        <div class="grid gap-4">
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-6.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-7.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-8.jpg" alt="" />
                            </div>
                        </div>
                        <div class="grid gap-4">
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-9.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-10.jpg" alt="" />
                            </div>
                            <div>
                                <img class="h-auto max-w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/docs/gallery/masonry/image-11.jpg" alt="" />
                            </div>
                        </div>
                    </div> */}



                </div>
            </section>
        </div>
    );
};

export default Progress;