import React from 'react';
import gallery_2 from '../../../../assets/img/kids/cropped-kid2-new.JPG'

const Vision = () => {
    return (
        <div>

            <div class="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl lg:mt-4  lg:mb-12 xl:max-w-6xl">

                <div class="w-full h-64 lg:w-1/2 lg:h-auto">
                    <img class="h-full w-full object-cover" src={gallery_2} alt="Winding mountain road" />
                </div>

                <div
                    class="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-48 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
                    <div class="flex flex-col p-12 px-5 md:px-16">
                        <h2 class="sm:text-4xl text-3xl font-bold text-blue-400  text-center lg:text-left">Why Choose Al–Maghrib</h2>
                        <p class="mt-4 text-justify ">
                            People opt for Al-Maghrib International School due to its comprehensive curriculum, combining the Cambridge curriculum with an
                            Islamic focus. This approach instills Islamic values in students while equipping them with skills to navigate 21st-century
                            challenges, fostering the development of global citizens. Emphasizing the compatibility of Islam
                            with math and science, the school promotes holistic development, incorporating play-based learning in the Early Years Foundation
                            Stage (EYFS) and adhering to Cambridge primary guidelines in primary education. The vision is to nurture students who can serve
                            as mosque leaders, engineers, doctors, embodying both strong Muslim identity and a commitment to national and global
                            contributions.
                        </p>
                        {/* <div class="mt-8">
                            <button
                                className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                type="button"
                                style={{ transition: "all .15s ease" }}
                            >
                                Read More
                            </button>
                        </div> */}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Vision;