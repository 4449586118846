import { faLayerGroup, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import Meta from '../../meta/Meta';

const AdmissionProcess = () => {
    const pageDescription = "Admission procedure and age factors for a candidate. ";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/admission/process";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Admission Process | Al-Maghrib';
    }, []);
    return (
        <>
            <Meta pageTitle={"Admission Process | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/premium-photo/business-growth-process-economic-improvement-concept-white-rising-arrow-with-wooden-cube-blocks-arranged-as-chart-stair-steps-up-wood-sphere-with-target-icon-top-blue-background_36367-2533.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=sph')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Admission Process
                                    </h1>
                                    {/* <p className="mt-4 text-lg text-gray-300">
                                    <strong>...</strong> <br />
                                </p> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="lg:pt-12 pt-6 w-full md:w-12/12 ">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-center">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <FontAwesomeIcon icon={faStar} size="lg" />
                                        </div>
                                    </div>
                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-xl text-center lg:text-left font-semibold">Curriculum and Session</h6>
                                        </div>
                                        <div className="lg:pt-6 w-full md:w-9/12 px-4">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p className="text-center lg:text-left font-normal text-gray-700 dark:text-gray-400 ">Cambridge curriculum (July-June)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Admission Form</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Admission form is attached with the school website, you can download it.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        No documents required for collecting the admission form.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Can be collected from school’s office or from the website.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Submission Procedure</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Relevant documents along with admission form have to be submitted. These documents are:
                                                        <br /> <br />
                                                        a. 2 copies of passport size photograph <br />
                                                        b. Attested Birth Certificate <br />
                                                        c. Latest Report Card <br />
                                                        d. TC (If any)

                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Admission form has to be submitted within deadline. Anyone can submit the form.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Admission Test</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        Student has to sit for a written test. (Except Play, Nursery and Reception. They only sit for an oral test.)
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Timing of written Test: <br /><br />
                                                        Class 1 to Class 5: 1 to 1.5 hrs.

                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        After passing the written test then student has to face a VIVA with Principal/CEO/AD/VP. Both the parents need to be present at VIVA.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class=" mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        Then the final result sheet will be published at our school notice board/website within 2 working days.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">Final Formalities</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        After passing both the written test and VIVA & publishing the result, the parent/ guardian needs to collect the admission money
                                                        receipt from the Front office or the accounts officer.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        The payment has to be deposited at earliest at the particular bank or at the school Accounts office as mentioned in the money
                                                        receipt within the date specified.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left  text-xl font-semibold">Books and Exercises Copies</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        After depositing the money at bank, the parent/guardian has to show the money receipt at school’s Store/Book delivery point. The
                                                        concerned personnel will deliver the necessary books and exercise copies.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:flex">
                                        <div className="pt-6 w-full md:w-3/12 px-4 ">
                                            <h6 className="text-center lg:text-left text-xl font-semibold">School Uniform</h6>
                                        </div>
                                        <div className=" lg:pt-6 w-full md:w-9/12 px-4 ">
                                            <div class="divide-y divide-slate-200 ">
                                                <div>
                                                    <p class="mb-3  font-normal text-gray-700 dark:text-gray-400">
                                                        For school uniform, parents can take their children to the tailor room for necessary measurement along with the payment.
                                                    </p>
                                                </div>
                                                <div>
                                                    <p class="mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                        All the rates of uniforms are approved by the school.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="lg:pt-12 pt-6 w-full md:w-12/12  ">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                <div className="px-4 py-5 flex-auto">
                                    <div className="text-center">
                                        <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                            <FontAwesomeIcon icon={faLayerGroup} size="lg" />
                                        </div>
                                        <h6 className="text-xl text-center font-semibold">Students Year Group and Age Range</h6>
                                    </div>
                                    <div className="flex flex-wrap justify-center content-center  " >
                                        <div className="w-full mt-4  md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Play <br /> <br />
                                                    3 to 4 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Nursery	 <br /> <br />
                                                    4 to 5 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Reception <br /> <br />
                                                    5 to 6 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Key Stage 1 (Year 1)	 <br /> <br />
                                                    6 to 7 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Key Stage 1 (Year 2)	 <br /> <br />
                                                    7 to 8 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Key Stage 1 (Year 3)	 <br /> <br />
                                                    8 to 9 years
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="w-full md:mt-4  md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                            <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                                <h5 class="mb-2 text-xl text-center font-semibold tracking-tight  dark:text-white">
                                                    Key Stage 1 (Year 4)	 <br /> <br />
                                                    9 to 10 years
                                                </h5>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default AdmissionProcess;