import React, { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  ListBulletIcon,
  SquaresPlusIcon,
  StarIcon,
  XMarkIcon,
  CheckBadgeIcon,
  UserIcon,
  AcademicCapIcon,
  NewspaperIcon,
  ClipboardDocumentIcon,
  ArrowDownTrayIcon,
  CalendarDaysIcon,
  PhotoIcon,
  BookOpenIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import logo from '../../assets/img/school/web logo@4x.png'
import { Link } from 'react-router-dom'
import admission_form from '../../assets/pdf/Admission_Form_Al-Maghrib.pdf'


const callsToAction = [
  { name: 'Visit youtube', href: '#', icon: PlayCircleIcon },
  { name: 'Contact us', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Navbar2 = () => {
  const [hoveredMenu, setHoveredMenu] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const menus = [
    {
      name: 'About', items: [
        { name: 'Vision', description: 'Current and future objectives of the school', href: '/about/vision', icon: StarIcon },
        { name: 'Values', description: 'Get a better understanding of our school', href: '/about/values', icon: ListBulletIcon },
        { name: 'Core Academic Principles', description: 'Fundamental concepts that serve our system', href: '/about/core-academic-principles', icon: CheckBadgeIcon },
        { name: 'Chairman & Principal', description: 'Message of Mr. M Mohibbullah Helal', href: '/about/principal', icon: UserIcon },
        { name: 'CEO & Academic Director', description: 'Message of Mr. Md Neazul Hoque', href: '/about/ceo', icon: UserIcon },
        { name: 'Vice Principal', description: 'Message of Mr. Mohammad Emdad Ullah', href: '/about/vice-principal', icon: UserIcon },
        { name: 'Management', description: 'Executive board of the school', href: '/about/management', icon: SquaresPlusIcon },
      ],
    },
    {
      name: 'Academic', items: [
        { name: 'Curriculum', description: 'What will be taught to the students', href: '/academic/curriculum', icon: AcademicCapIcon },
        { name: 'Progress', description: 'More than one year of Al-Maghrib', href: '/academic/progress', icon: CursorArrowRaysIcon },
        { name: 'Policies', description: 'Rules and regulations to guide acceptable behavior', href: '/academic/policies', icon: FingerPrintIcon },
      ],
    },
    {
      name: 'Admission', items: [
        { name: 'Admission News', description: 'Check out for the latest news on school admissions', href: '/admission/news', icon: NewspaperIcon },
        { name: 'Admission Process', description: 'Admission procedure and age factors for a candidate', href: '/admission/process', icon: ClipboardDocumentIcon },
        { name: 'Download Admission Form', description: 'Admission form within a click', href: '#', icon: ArrowDownTrayIcon },
      ],
    },
    {
      name: 'News & Media', items: [
        { name: 'Regular Events', description: 'Different type of activities are planned to celebrate', href: '/events', icon: CalendarDaysIcon },
        { name: 'Photos & Videos', description: 'Beautiful memories of our school and students', href: '/gallery', icon: PhotoIcon },
        { name: 'Newsletter', description: 'Digital publication includes information on school events, announcements, and activities', href: '/newsletter', icon: BookOpenIcon },
      ],
    },
  ];

  const handleDownload = (e) => {
    e.preventDefault();
    const anchor = document.createElement('a');
    anchor.href = admission_form; // Provide the file URL here
    anchor.download = 'Admisssion_Form_Al-Maghrib'; // Provide the desired file name here
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div>
      <div className="navbar-marquee block">
        <p>Admission going on Al-Maghrib International School. Visit our campus to know more.</p>
      </div>
      <header className="bg-white ">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-4 md:px-16 2xl:px-0" aria-label="Global">
          <div className="flex items-center">
            <Link to="/" className="p-0 md:p-2 2xl:px-0">
              <div className="flex items-center">
                <img className="h-12 w-auto" src={logo} alt="Logo" />
                <div className="ml-4">
                  <p className="text-2xl font-bold text-blue-400">Al-Maghrib</p>
                  <p className="text-sm font-bold text-blue-400">International School</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="block lg:hidden -m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex justify-end lg:gap-x-6 ml-5" >
            <Link to="/" className="text-sm font-semibold leading-6 text-gray-900">
              Home
            </Link>
            {/* <Popover className="relative">
              <Popover.Button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${isHovered ? 'hover:text-blue-400' : ''}`}
              >
                About
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={React.Fragment}
                show={isHovered}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="p-4 ">
                    {abouts.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon className="h-6 w-6 text-gray-600 group-hover:text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                          <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-blue-400">
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600 group-hover:text-blue-400">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <Popover className="relative">
              <Popover.Button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${isHovered ? 'hover:text-blue-400' : ''}`}
              >
                Academic
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={React.Fragment}
                show={isHovered}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="p-4 ">
                    {academics.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon className="h-6 w-6 text-gray-600 group-hover:text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                          <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-blue-400">
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600 group-hover:text-blue-400">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

             <Popover className="relative">
              <Popover.Button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${isHovered ? 'hover:text-blue-400' : ''}`}
              >
                Admission
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={React.Fragment}
                show={isHovered}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="p-4 ">
                    {admissions.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon className="h-6 w-6 text-gray-600 group-hover:text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                          <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-blue-400">
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600 group-hover:text-blue-400">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    {callsToAction.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                      >
                        <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <Popover className="relative">
              <Popover.Button
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                className={`flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${isHovered ? 'hover:text-blue-400' : ''}`}
              >
                News & Media
                <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              </Popover.Button>

              <Transition
                as={React.Fragment}
                show={isHovered}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel
                  className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div className="p-4 ">
                    {medias.map((item) => (
                      <div
                        key={item.name}
                        className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                      >
                        <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon className="h-6 w-6 text-gray-600 group-hover:text-blue-400" aria-hidden="true" />
                        </div>
                        <div className="flex-auto">
                          <a href={item.href} className="block font-semibold text-gray-900 group-hover:text-blue-400">
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600 group-hover:text-blue-400">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                    {callsToAction.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                      >
                        <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        {item.name}
                      </a>
                    ))}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover> */}
            {menus.map((menu) => (
              <div key={menu.name} className="relative group">
                <Popover className="relative">
                  <Popover.Button
                    onMouseEnter={() => setHoveredMenu(menu.name)}
                    className={`flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 ${hoveredMenu === menu.name ? 'hover:text-blue-400' : ''}`}
                  >
                    {menu.name}
                    <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  </Popover.Button>

                  {hoveredMenu === menu.name && (
                    <div
                      className="absolute -left-40 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5"
                      onMouseEnter={() => setHoveredMenu(menu.name)}
                      onMouseLeave={() => setHoveredMenu(null)}
                    >
                      <div className="p-4 ">
                        {menu.items.map((item) => (
                          <div
                            key={item.name}
                            className=" relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-blue-50"
                          >
                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                              <item.icon className="h-6 w-6 text-gray-600 hover:text-blue-400" aria-hidden="true" />
                            </div>
                            <div className="flex-auto">
                              <Link to={item.href} className="block font-semibold text-gray-900 hover:text-blue-400">
                                {item.name}
                                <span className="absolute inset-0" onClick={item.name === 'Download Admission Form' ? handleDownload : null}/>
                              </Link>
                              <p className="mt-1 text-gray-600 hover:text-blue-400">{item.description}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                      {/* <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                        {callsToAction.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                          >
                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                      </div> */}
                    </div>
                  )}
                </Popover>
              </div>
            ))}


            <Link to="careers" className="text-sm font-semibold leading-6 text-gray-900">
              Career
            </Link>
            <Link to="/contact-us" className="text-sm font-semibold leading-6 text-gray-900">
              Contact
            </Link>
          </Popover.Group>
          {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div> */}
        </nav>
        
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            
            <div className="flex items-center justify-between">
              
              <a href="/" className="-m-1.5 p-1.5">
                <div className="flex flex-wrap items-center">
                  <img className="h-12 w-auto" src={logo} alt="" />
                  {/* <p className=" text-blue-400 font-semibold uppercase">Al-Maghrib International School</p> */}
                  <div className="ml-4">
                  <p className="text-2xl font-bold text-blue-400">Al-Maghrib</p>
                  <p className="text-sm font-bold text-blue-400">International School</p>
                </div>
                </div>
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  <a
                    href="/"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Home
                  </a>
                  {/* <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          About
                          <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...abouts, ...callsToAction].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}

                  {/* <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Academic
                          <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...academics, ...callsToAction].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}

                  {/* <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          Admission
                          <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...admissions, ...callsToAction].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}

                  {/* <Disclosure as="div" className="-mx-3">
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                          News & Media
                          <ChevronDownIcon
                            className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                            aria-hidden="true"
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-2 space-y-2">
                          {[...medias, ...callsToAction].map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure> */}

                  {
                    menus.map((menu, index) => (
                      <Disclosure as="div" key={index} className="-mx-3">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                              {menu.name}
                              <ChevronDownIcon
                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-2 space-y-2">
                              {[...menu.items].map((item) => (
                                <Disclosure.Button
                                  key={item.name}
                                  as='a'
                                  href={item.href}
                                  className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-blue-50"
                                  onClick={item.name === 'Download Admission Form' ? handleDownload : null}
                                >
                                  {item.name}
                                </Disclosure.Button>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))
                  }

                  <a
                    href="/careers"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Career
                  </a>
                  <a
                    href="/contact-us"
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Contact
                  </a>
                </div>
                {/* <div className="py-6">
                  <a
                    href="#"
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </a>
                </div> */}
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

    </div>
  );
};

export default Navbar2;