import React, { useEffect } from 'react';
import vice_principal_pic from '../../../assets/img/board_members/emdad.jpg';
import school from '../../../assets/img/school/newSchool.png';

const VicePrincipal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Vice Principal | Al-Maghrib";
    }, []);
    return (
        <>
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "55vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/premium-vector/modern-blue-abstract-background-vector-illustration-design-presentation-banner-cover-web-flyer-card-poster-wallpaper-texture-slide-magazine-powerpoint_181182-19659.jpg?w=900')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>

                    <div className="container  relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="mt-5 flex flex-col items-center md:flex-row  px-12" >
                                <div className="w-full md:w-6/12 lg:w-6/12  md:mr-auto" >
                                    <div className="text-center ">
                                        <h1 className="text-white font-bold text-4xl md:text-6xl lg:text-7xl ">
                                            Vice Principal's Message
                                        </h1>
                                        <p className="mt-4 mb-4 text-xl md:text-2xl text-gray-300 border border-blue-400 p-1 md:p-4">
                                            <strong>Mohammad Emdad Ullah</strong> <br />

                                        </p>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 lg:w-6/12 px-4 ml-auto md:block flex justify-center" >
                                    <div className="w-full md:w-3/4 p-1 md:p-2">
                                        <img
                                            alt="gallery"
                                            class="block h-full w-full rounded-lg object-cover object-center"
                                            src={vice_principal_pic}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <section className=" pt-20 pb-20 bg-blue-50 -mt-28">
                    <div className="mt-24 flex justify-center">
                        <div className="container px-4 md:px-16 mt-5">
                            <div className='text-justify'>
                                Dear Al-Maghrib International School Community,
                                <br /><br />
                                As we bid farewell to another enriching unit filled with learning, growth, and shared accomplishments, I am excited to reflect on the remarkable journey we continue to undertake together. The past few months have been a testament to the dedication and resilience of our students, faculty, and staff.
                                <br /><br />
                                Our students have once again demonstrated their commitment to academic excellence. From impressive assessment results to engaging classroom projects, it's clear that the pursuit of knowledge remains at the forefront of our collective efforts. I appreciate each student for their hard work, and implementation of the teachers' advice and encourage them to maintain this passion for learning.
                                <br /><br />
                                Beyond the classroom, our students have excelled in various extracurricular activities. Whether it's sports, arts, various types of competition, or community service, their accomplishments are a testament to the well-rounded education we provide. I applaud our students for their diverse talents and encourage them to explore new avenues of interest. Looking ahead, we have an exciting lineup of events and opportunities planned for the upcoming months.
                                <br /><br />
                                Lastly, I want to express my gratitude to our parents, guardians, and the entire Al-Maghrib community. Your support plays a crucial role in the success of our students and the overall well-being of our school. Thank you for being an integral part of the Al-Maghrib family.
                                <br /><br />
                                Here is to another few months of growth, achievement, and shared success!
                                <br /><br />
                                Best regards, <br />
                                <b>Mohammad Emdad Ullah</b> <br />
                                Vice Principal

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default VicePrincipal;