import React from 'react';
import './FooterNew.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCalendarDays, faCircleXmark, faClock, faEnvelope, faGraduationCap, faLocationDot, faMagnifyingGlass, faPhone, faSchool, faSchoolLock } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';


const FooterNew = () => {
    return (
        <footer className="relative bg-blue-400 pt-8 pb-6">
            {/* <div
                className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                style={{ height: "80px" }}
            >
                <svg
                    className="absolute bottom-0 overflow-hidden"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="none"
                    version="1.1"
                    viewBox="0 0 2560 100"
                    x="0"
                    y="0"
                >
                    <polygon
                        className="text-blue-400 fill-current"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> */}



            <div className='footer-new '>
                <footer className="padding_4x">
                    {/* <h3 className="text-white font-bold   text-center"> <FontAwesomeIcon icon={faGraduationCap} /> <br />
                 AL-MAGHRIB INTERNATIONAL SCHOOL</h3> */}
                    <div className="flex ">
                        <section className="flex-content padding_1x" >
                            <h3>Contact Us</h3>
                            <p>
                                <FontAwesomeIcon icon={faLocationDot} size="lg" /> <br />
                                House-131/1, Road-01, South Khulshi, Chittagong, Bangladesh
                            </p>
                            <br />
                            <p>
                                <FontAwesomeIcon icon={faPhone} /> <br />
                                +880-1766-386905 <br /> +880-1835-348137
                            </p>
                            <br />
                            <p>
                                <FontAwesomeIcon icon={faEnvelope} /> <br />
                                <a href="mailto:info@almaghribschool.com">info@almaghribschool.com</a>

                            </p>
                        </section>
                        <section className="flex-content padding_1x" >
                            <h3>Quick Links</h3>
                            <Link to="/academic/curriculum">
                                <FontAwesomeIcon icon={faGraduationCap} className='mr-1' />
                                Academics <br />
                            </Link>
                            <Link to="/admission/news">
                                <FontAwesomeIcon icon={faAddressCard} className='mr-1' />
                                Admission <br />
                            </Link>
                            <Link to="/events">
                                <FontAwesomeIcon icon={faCalendarDays} className='mr-1' />
                                News and Events <br />
                            </Link>
                            <Link to="/careers">
                                <FontAwesomeIcon icon={faMagnifyingGlass} className='mr-1' />
                                Career <br />
                            </Link>

                            <a href="https://goo.gl/maps/6mk4HV6pMQxGda749">
                                <FontAwesomeIcon icon={faLocationDot} className='mr-1' />
                                Visit Our Campus
                            </a>
                        </section>
                        <section className="flex-content padding_1x" >
                            <h3>Office Timings</h3>
                            <p>
                                <FontAwesomeIcon icon={faClock} className='mr-1' />
                                Sun-Thu: 9AM - 3PM</p>
                            <p>
                                <FontAwesomeIcon icon={faCircleXmark} className='mr-1' />
                                Fri & Sat: Closed</p>
                        </section>
                        <section className="flex-content padding_1x" >
                            <h3>Newsletter</h3>
                            <p>Get informed whenever a publication is online.</p>
                            <fieldset className="fixed_flex">
                                <input type="email" name="newsletter_email" placeholder="Email" />
                                <button className="btn btn_2 p-2">Subscribe</button>
                            </fieldset>
                        </section>
                    </div>
                    <div className="flex">
                        <section className="flex-content padding_1x">
                            <p>©2023 Al-Maghrib International School. All rights reserved.</p>
                            {/* <code>Developed by KHWAAB</code> */}
                        </section>
                        <section className="flex-content padding_1x social-media-box ">
                            <span><a href="https://www.facebook.com/almaghribschool">
                                <FontAwesomeIcon icon={faFacebook} />
                            </a></span>
                            <span><a href="https://www.youtube.com/channel/UC8Za4oYaCnNIohZXvXXEkUw">
                                <FontAwesomeIcon icon={faYoutube} />
                            </a></span>
                            <span><a href="mailto:info@almaghribschool.com">
                                <FontAwesomeIcon icon={faEnvelope} />
                            </a></span>
                        </section>
                    </div>
                </footer>
            </div>
        </footer>




    );
};

export default FooterNew;