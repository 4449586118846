import React, { useEffect, useState } from 'react';
import UpcomingEvents from '../UpcomingEvents/UpcomingEvents';
import Vision from '../Vision/Vision';
import Responsibility from '../Responsibility/Responsibility';
import Values from '../Values/Values';
import CustomSlider from '../../CustomeSlider';
import { Link } from 'react-router-dom';
import curriculum1 from '../../../../assets/img/others/curriculum1.JPG'
import curriculum2 from '../../../../assets/img/others/curriculum2.JPG'
import curriculum3 from '../../../../assets/img/others/curriculum3.JPG'
import curriculum4 from '../../../../assets/img/others/curriculum4.JPG'
import curriculum5 from '../../../../assets/img/others/curriculum5.JPG'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faCalendarDays, faFingerprint } from '@fortawesome/free-solid-svg-icons';
import Board from '../Board/Board';
import Meta from '../../../meta/Meta';
import { Helmet } from 'react-helmet-async';

const Hero = () => {
    const pageDescription = "Al-Maghrib International School started its journey in April 2022. We are an English-medium Cambridge curriculum school, aimed at providing quality education while maintaining an Islamic environment for the students. We want to grow more with your retained trust in us and with Allah's (SWT) help above all. We provide our students with a broad and balanced education in Islamic environment to develop their academic skills and Islamic values in order to practice Islam with sincerity."
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/";

    useEffect(() => {
        document.title = 'Al-Maghrib International School';

    }, []);

    const curriculums = [
        { pic: curriculum1, text: "Academic: Cambridge Early Years Foundation Stage (Play, Nursery and Reception)" },
        { pic: curriculum2, text: "Islamic Studies" },
        { pic: curriculum3, text: "Cambridge Primary - Key Stage 1 & 2 (Class 1 to 6)" },
        { pic: curriculum4, text: "Character Development Curriculum" },
        { pic: curriculum5, text: "Hifz Curriculum" },
    ]

    return (
        <>
            {/* <Meta pageTitle={"Al-Maghrib International School"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} /> */}
            <Helmet>
                {/* Common meta tags */}
                <title>Al-Maghrib International School</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph meta tags for Facebook and other platforms */}
                <meta property="og:title" content="Al-Maghrib International School" />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {/* <meta name="twitter:site" content={twitterUsername} /> */}
                <meta name="twitter:title" content="Al-Maghrib International School" />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />

                {/* Additional meta tags for various platforms */}
                <meta property="al:web:url" content={pageUrl} />
            </Helmet>


            <div>
                <main>
                    <div className="relative pt-8 2xl:pt-0 pb-32 px-2 flex content-center items-center justify-center"
                        style={{
                            minHeight: "75vh"
                        }}>
                        <div className="absolute top-0 w-full h-full bg-center bg-cover bg-blue-400"
                            style={{
                                // backgroundImage: "url('https://images.unsplash.com/photo-1600303881706-b8a373dc73c3?auto=format&fit=crop&q=60&w=400&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTl8fHxlbnwwfHx8fHw%3D')"
                            }}>
                            {/* <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span> */}
                        </div>

                        <div className="container px-0 lg:px-4 relative mx-auto">
                            <div className="items-center flex flex-wrap">
                                <div className=" flex flex-col items-center lg:flex-row mx-auto px-0 md:px-4" >
                                    <div className="w-full md:w-full lg:w-7/12 xl:w-7/12  ml-auto  md:block" >
                                        <div className="">
                                            <CustomSlider />
                                        </div>
                                    </div>
                                    <div className="w-full md:w-full lg:w-5/12 xl:w-5/12  md:mr-auto" >
                                        {
                                            curriculums.map(curriculum =>
                                                <div className="px-2 py-2 2xl:py-4">
                                                    <div className="flex items-center">
                                                        <div className="mr-5">
                                                            <div className="rounded-full overflow-hidden h-12 w-12 md:h-12 md:w-12  ">
                                                                <img
                                                                    alt="..."
                                                                    src={curriculum.pic}
                                                                    className="object-cover object-center h-full w-full"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <Link to="/academic/curriculum" >
                                                                <h3 className="text-base lg:text-xl font-bold text-white">{curriculum.text}</h3>
                                                            </Link>

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
                            style={{ height: "70px" }}
                        >
                            <svg
                                className="absolute bottom-0 overflow-hidden"
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                version="1.1"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    // className="text-gray-300 fill-current"
                                    className="text-white fill-current"
                                    points="2560 0 2560 100 0 100"
                                ></polygon>
                            </svg>
                        </div>
                    </div>

                    <section className="pb-5 bg-white -mt-24">
                        <div className="container mx-auto px-4">
                            <div className="flex flex-wrap md:px-12">
                                <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                        <Link to="/admission/news">
                                            <div className="px-4 py-5 flex-auto">
                                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                                                    <FontAwesomeIcon icon={faAddressCard} />
                                                </div>
                                                <h6 className="text-xl font-semibold">Enrollment News</h6>
                                                <p className="mt-2 mb-4 text-gray-600">
                                                    Join our institution. Participate in a universe of excitement and education.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className="w-full md:w-4/12 px-4 text-center">
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                        <Link to="/events">
                                            <div className="px-4 py-5 flex-auto">
                                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                                    <FontAwesomeIcon icon={faCalendarDays} />
                                                </div>
                                                <h6 className="text-xl font-semibold">
                                                    Regular Events
                                                </h6>
                                                <p className="mt-2 mb-4 text-gray-600">
                                                    Discover the beauty of regular Islamic events, fostering understanding, unity, and spiritual growth in a nurturing environment.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                <div className="pt-6 w-full md:w-4/12 px-4 text-center" >
                                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                                        <Link to="/notices">
                                            <div className="px-4 py-5 flex-auto">
                                                <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                                                    <FontAwesomeIcon icon={faFingerprint} />
                                                </div>
                                                <h6 className="text-xl font-semibold">
                                                    Notice Board
                                                </h6>
                                                <p className="mt-2 mb-4 text-gray-600">
                                                    Stay informed with the latest announcements and upcoming news at Al-Maghrib International School.
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>

                <UpcomingEvents />
                <Vision />
                <Responsibility />
                <Values />
                <Board />
            </div>
        </>
    );
};

export default Hero;