import React, { useEffect, useState } from 'react';
import './Gallery.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faCirclePlay, faImage, faVideo } from '@fortawesome/free-solid-svg-icons';

const Gallery = () => {
    const [allEvents, setAllEvents] = useState([]);
    const [allImages, setAllImages] = useState([]);
    const [allVideos, setAllVideos] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [videosClicked, setVideosClicked] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Photos & Videos | Al-Maghrib';

        fetch('https://al-maghrib-server.vercel.app/magazine/all')
            .then(res => res.json())
            .then(data => {
                const reversedArray = data.magazines.reverse();
                const allImagesInfo = reversedArray.map(event => {
                    return { headline: event.headline, imagesInfo: event.imagesInfo };
                });
                setAllEvents(allImagesInfo);

                const allImages = reversedArray.flatMap(event => {
                    return event.imagesInfo.map(imagesInfo => imagesInfo.url);
                });
                setAllImages(allImages);

                const allVideos = reversedArray.flatMap(event => {
                    return event.videosInfo.map(videoInfo => videoInfo.value);
                });
                setAllVideos(allVideos);
            });
    }, []);
    const handleEventClick = (event) => {
        setSelectedEvent(event);
        setVideosClicked(false)
    };

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [newAllImages, setNewAllImages] = useState([]);

    const openLightbox = (index) => {
        const filteredAllImages = selectedEvent === null ? allImages
            : allImages.filter(image => allEvents.find(event => event.headline === selectedEvent.headline).imagesInfo.find(info => info.url === image))
        setNewAllImages(filteredAllImages)

        setSelectedImage(index);
        setLightboxOpen(true);
    };

    const closeLightbox = () => {
        setLightboxOpen(false);
        setSelectedImage(null);
    };

    const navigate = (direction) => {
        const newIndex = (selectedImage + direction + newAllImages.length) % newAllImages.length;
        setSelectedImage(newIndex);
    };



    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "45vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://c0.wallpaperflare.com/preview/46/458/375/art-border-display-interior.jpg')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                            <div className="">
                                <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    Photos & Videos
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong></strong>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">

                    <div class="flex items-center justify-center py-4 md:py-8 flex-wrap">
                        {allVideos.length ?
                            <button
                                type="button"
                                className={`text-blue-700 hover:text-white border border-blue-600 ${videosClicked === true ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-400 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800'
                                    } focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3`}

                                onClick={() => setVideosClicked(true)}
                            >
                                <FontAwesomeIcon icon={faCirclePlay} /> Videos
                            </button>
                            : ''
                        }
                        <button
                            type="button"
                            className={`text-blue-700 hover:text-white border border-blue-600 ${selectedEvent === null && videosClicked === false ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-400 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800'
                                } focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-3 py-2 md:px-5 md:py-2.5 text-center me-3 mb-3`}

                            // onClick={() => setSelectedEvent(null)}
                            onClick={() => handleEventClick(null)}
                        >
                            <FontAwesomeIcon icon={faImage} /> All Photos
                        </button>

                        {/* {allEvents.length ? (
                            allEvents.map(event => (
                                <button
                                    key={event.headline}
                                    type="button"
                                    className={`text-blue-700 hover:text-white border border-blue-600 ${selectedEvent === event ? 'bg-blue-500 text-white' : 'bg-white hover:bg-blue-400 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800'
                                        } focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center me-3 mb-3`}
                                    onClick={() => handleEventClick(event)}
                                >
                                    {event.headline}
                                </button>
                            ))
                        ) : (
                            <div class="text-center">
                                <div role="status">
                                    <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        )} */}
                    </div>



                    {
                        !videosClicked ?
                            <div className="image-container">
                                {selectedEvent
                                    ? allImages
                                        .filter(image => allEvents.find(event => event.headline === selectedEvent.headline).imagesInfo.find(info => info.url === image))
                                        .map((image, index) => (
                                            <div key={index} className="image-box">
                                                <img key={image} src={image} alt={image}
                                                    onClick={() => openLightbox(index)}
                                                    loading='lazy'
                                                />
                                            </div>
                                        ))
                                    : allImages.map((image, index) => (
                                        <>
                                            <div key={index} className="image-box ">
                                                <img key={image} src={image} alt={image}
                                                    onClick={() => openLightbox(index)}
                                                    loading='lazy'
                                                />
                                            </div>
                                        </>
                                    ))}
                            </div>
                            :
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
                                {
                                    allVideos.map((video, index) => (
                                        video != "" &&
                                        <div key={index}>
                                            <iframe width="560" height="315"
                                                src={video}
                                                title="YouTube video player"
                                                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                allowFullscreen
                                                className="block w-full rounded-lg object-cover object-center"
                                            ></iframe>
                                        </div>

                                    ))
                                }
                            </div>
                    }



                    {lightboxOpen && (
                        <div className="fixed inset-0 z-50 overflow-hidden flex items-center justify-center">
                            <div
                                className="absolute inset-0 bg-black opacity-75 cursor-pointer"
                                onClick={closeLightbox}
                            ></div>

                            <div className="relative max-w-screen-md">
                                <button
                                    className="absolute top-0 bottom-0 px-4 bg-transparent text-white text-2xl cursor-pointer focus:outline-none"
                                    onClick={() => navigate(-1)}
                                >
                                    &#8249;
                                </button>

                                <img
                                    src={newAllImages[selectedImage]}
                                    alt={`galleryImage ${selectedImage}`}
                                    className="max-h-screen max-w-full"
                                    loading='lazy'
                                />

                                <button
                                    className="absolute top-0 bottom-0 right-0 px-4 bg-transparent text-white text-2xl cursor-pointer focus:outline-none"
                                    onClick={() => navigate(1)}
                                >
                                    &#8250;
                                </button>
                            </div>
                        </div>
                    )}







                </div>
            </section>
        </div>
    );
};

export default Gallery;