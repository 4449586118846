import React, { useEffect } from 'react';
import Meta from '../../meta/Meta';

const Curriculum = () => {
    const pageDescription = "Explore our comprehensive curriculum, including Cambridge Early Years, Islamic Studies, Cambridge Primary, Character Development, and Hifz programs. Nurturing holistic growth and academic excellence for a purposeful education journey";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/academic/curriculum";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Curriculum | Al-Maghrib";
    }, []);
    return (
        <>
            <Meta pageTitle={"Curriculum | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div>
                    <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                        style={{
                            minHeight: "45vh"
                        }}>
                        <div className="absolute top-0 w-full h-full bg-center bg-cover bg-blue-400"
                        // style={{
                        //     backgroundImage: "url('https://c0.wallpaperflare.com/preview/498/976/47/success-stairs-board-drawing.jpg')"
                        // }}
                        >
                            {/* <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span> */}
                        </div>
                        <div className="container relative mx-auto">
                            <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                            School Curriculum
                                        </h1>
                                        <p className="mt-4 text-lg text-gray-300">
                                            The leadership and management of Al-Magrib International School are shared by the Leadership
                                            Team consisting of the Chairman/Principal, CEO/AD, Vice Principal, Academic Coordinator, Activity
                                            Coordinator and Teachers. The Leadership Team is responsible for implementing the strategic plan
                                            in line with the Vision and values of the school. The School Leadership Team is responsible to the
                                            Chairman/CEO/Academic Director/Principal of the school.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="flex flex-wrap content-center justify-center " >

                            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 " >
                                <div className="py-6 px-0 md:px-6  flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?auto=format&fit=crop&q=60&w=400&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTV8fG51cnNlcnklMjBzY2hvb2x8ZW58MHx8MHx8fDA%3D" alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Academic: Cambridge Early Years Foundation Stage (Play, Nursery and Reception)
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                <div class="divide-y divide-slate-200 ">
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Communication and Language, Literacy
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Understanding of the World
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Mathematic
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Expressive Arts & Design
                                                        </p>
                                                    </div>
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12" >
                                <div className="py-6  px-0 md:px-6  flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src="https://c4.wallpaperflare.com/wallpaper/376/349/121/allah-god-islam-entertainment-other-hd-art-wallpaper-preview.jpg" alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Islamic Studies
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                <div class="divide-y divide-slate-200 ">
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Quran-Tajweed & Hifz
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Arabic Language
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Islamic Studies (Aqeedha, Fiqh and Hadith)
                                                        </p>
                                                    </div>
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12" >
                                <div className="py-6 px-0 md:px-6  flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src="https://images.unsplash.com/photo-1498243691581-b145c3f54a5a?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGhpZ2glMjBzY2hvb2wlMjBjbGFzc3Jvb218ZW58MHx8MHx8fDA%3D" alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Cambridge Primary - Key Stage 1 & 2 (Class 1 to 6)
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                <div class="divide-y divide-slate-200 ">
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            English
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Science
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Mathematic
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Computing
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Bangla
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            DTAC (Design, Technology, art & craft)
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Physical Education
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Citizenship (Bangladesh History, Culture & Geography)
                                                        </p>
                                                    </div>
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12" >
                                <div className="py-6 px-0 md:px-6  flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src="https://c0.wallpaperflare.com/preview/560/693/69/business-background-illustration-people.jpg" alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Character Development Curriculum
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                <div class="divide-y divide-slate-200 ">
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Muslim Heroes (Biography of Prophet Mohammed (SAW) and His companions)
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Social Theme
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Tarbiya Program
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Boot Camp
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Extended Curriculum Activities
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Physical Education
                                                        </p>
                                                    </div>
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12" >
                                <div className="py-6 px-0 md:px-6 flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src="https://i0.wp.com/www.ilmgate.org/wp-content/uploads/2014/01/hifz-e-quran.jpg" alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Hifz Curriculum
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                <div class="divide-y divide-slate-200 ">
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Quran, Tajweed, Tarbiyyah
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Strict Disciplines
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                                            Memorizing Techniques
                                                        </p>
                                                    </div>
                                                </div>
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Curriculum;