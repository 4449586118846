import React from 'react';
import apic from '../../../../assets/img/kids/academic1.PNG'

const Responsibility = () => {
    return (
        <div>
            <section className="relative pt-20">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-b-lg bg-blue-400">

                    <blockquote className="relative p-0 mb-4">
                        <svg
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 583 95"
                            className="absolute left-0 w-full block"
                            style={{
                                height: "95px",
                                top: "-94px"
                            }}
                        >
                            <polygon
                                points="-30,95 583,95 583,65"
                                className="text-blue-400 fill-current"
                            ></polygon>
                        </svg>
                        <div className="container mx-auto px-4">
                            <div className="lg:flex flex-wrap items-center ">
                                <div className="w-full lg:w-5/12 ml-auto mr-auto my-4 px-1 lg:px-6">
                                    <img
                                        alt="..."
                                        src={apic}
                                        className="w-full  align-middle rounded-t-lg"
                                    />
                                </div>
                                <div className="w-full lg:w-6/12 ml-auto mr-auto my-4 px-1 lg:px-4">
                                    <h4 className="sm:text-4xl text-3xl font-bold text-white tracking-tight text-center lg:text-left">
                                        Your Children, Our Responsibility
                                    </h4>
                                    <p className="text-md text-justify font-normal mt-5 text-white">
                                        Welcome to Al-Maghrib International School, where excellence meets tradition. Established in 2022 in Chittagong, Bangladesh, we embark on a visionary journey “To be the best Islamic premium and transparent school that represents a holistic and caring environment that nurtures excellence in academic and moral education. We aim to create successful learners, Responsible Muslim Citizens, Confident Individuals and accountable leaders that will serve humanity. We also believe in character before brilliance and every child is unique and every child is capable of learning” Our commitment lies in providing a balanced educational experience that blends Islamic values, Islamic studies, and Quran recitation seamlessly with the renowned Cambridge curriculum.
                                        <br /><br />
                                        At Al-Maghrib, we aspire to nurture the leaders of tomorrow by offering a holistic education that goes beyond textbooks. Our approach is centered on instilling not only academic prowess but also a deep understanding of Islamic principles and values. Through the integration of Islamic teachings with the Cambridge curriculum, we aim to equip our students with the knowledge and character to excel in a rapidly evolving global landscape.
                                        <br /><br />
                                        Join us at Al-Maghrib, as we pave the way for a new generation of leaders, where education is a harmonious blend of academic excellence and spiritual enlightenment.

                                    </p>
                                </div>
                            </div>
                        </div>

                    </blockquote>
                </div>
            </section>
        </div>
    );
};

export default Responsibility;