import React from 'react';

const Values = () => {
    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto  sm:px-3  sm:py-32 md:py-8">
                    <div className="relative isolate overflow-hidden  pt-16 md:pt-0 lg:pt-0 sm:px-16  lg:flex lg:gap-x-20  ">
                        {/* <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#75aed6" />
                                    <stop offset={1} stopColor="#359ee9" />
                                </radialGradient>
                            </defs>
                        </svg> */}
                        <div className="container mx-auto px-1">
                            <div className="lg:flex flex-wrap items-center ">
                                <div className="w-full lg:w-12/12 ml-auto mr-auto flex justify-center my-4 lg:mt-20 bg-white  ">
                                    <div>
                                        <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto px-3 md:px-0 ">
                                            <div className='bg-blue-400 px-3 py-2 rounded-md'>
                                                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl text-center">
                                                    Our Values

                                                </h2>
                                            </div>
                                            {/* <p className="mt-6 text-lg leading-8 text-gray-900 text-center lg:text-left">
                                            Embodying diverse principles, our values drive excellence, discipline, unity, and a shared 
                                            commitment to a purpose-driven culture of our school.
                                            </p> */}

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 md:mt-0 w-full lg:w-12/12 ml-auto mr-auto flex justify-center my-4 p-3   ">
                                    <div>

                                        <div className="flex flex-wrap md:justify-center lg:justify-center">
                                            <div className="w-full mt-4  px-1 md:w-7/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md '>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg ">Tawheed & God Consciousness</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4  px-1  md:w-4/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Courage</h5>
                                                </div>
                                            </div>

                                            <div className="w-full mt-4  px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Charitable</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4  px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Patience</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Consultation</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Hard Work</h5>
                                                </div>
                                            </div>

                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Respect</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Excellence</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Discipline</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Humility</h5>
                                                </div>
                                            </div>

                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Gratitude</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-4/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Resistance to Evil</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Self-Control</h5>
                                                </div>
                                            </div>

                                            <div className="w-full mt-4 px-1  md:w-4/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Unity in Diversity</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Dependency</h5>
                                                </div>
                                            </div>
                                            <div className="w-full mt-4 px-1  md:w-3/12  ">
                                                <div className='p-4 border border-blue-200 rounded-lg shadow-md'>
                                                    <h5 class="text-blue-400 font-semibold text-lg text-center rounded-lg">Honesty</h5>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Values;