import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Meta from '../../meta/Meta';

const Events = () => {
    const pageDescription = "Experience the vibrancy of regular events at Al-Maghrib International School. Explore our students' diverse activities, both on and off-campus, where they learn, unwind, and build lasting memories. Discover a dynamic blend of education and enjoyment, fostering holistic growth and enriching experiences.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/events";

    const storedData = sessionStorage.getItem('events');
    const initialEvents = storedData ? JSON.parse(storedData) : [];
    const [events, setEvents] = useState(initialEvents);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "News and Events | Al-Maghrib";

        if (!storedData) {
            fetch('https://al-maghrib-server.vercel.app/magazine/all')
                .then(res => res.json())
                .then(data => {
                    const reversedArray = data.magazines.reverse();
                    setEvents(reversedArray);
                    sessionStorage.setItem('events', JSON.stringify(reversedArray));
                })
        }
    }, [])

    return (
        <>
            <Meta pageTitle={"News and Events | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div>
                    <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                        style={{
                            minHeight: "45vh"
                        }}>
                        <div className="absolute top-0 w-full h-full bg-center bg-cover"
                            style={{
                                backgroundImage: `url('https://cdn-az.allevents.in/events9/banners/5b4485266cd88ea080341191a1702506881edfde796411a057d292c972ef1a1b-rimg-w960-h640-gmir.jpg?v=1694733488')`
                            }}>
                            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                        </div>
                        <div className="container relative mx-auto">
                            <div className="items-center flex flex-wrap">
                                <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                    <div className="">
                                        <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                            School Events
                                        </h1>
                                        <p className="mt-4 text-lg text-gray-300">
                                            Explore the latest happenings, achievements, and exciting events on our News and Events page.
                                            From noteworthy accomplishments to upcoming activities, immerse yourself in the dynamic spirit
                                            of our educational journey. Engage, celebrate, and connect with the heartbeat of our school
                                            through compelling stories and impactful events.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="flex flex-wrap content-center justify-center " >
                            {/* {
                            events.length ? events.map((event, index) =>
                                <div key={index} className="w-full md:w-6/12 lg:w-4/12 lg:mb-0 mb-12 " >
                                    <div className="py-6 px-0 md:px-6 flex justify-center">
                                        <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                            <img class="rounded-t-lg" src={event.imagesInfo[0]?.url} alt="" />
                                            <div class="p-5">
                                                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                    {event.headline}
                                                </h5>
                                                <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                    {event.description1.slice(0, 150)}...
                                                </p>
                                                <div className="mt-6">
                                                    <Link to={`/events/${event._id}`}>
                                                        <button
                                                            className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                            type="button"
                                                            style={{ transition: "all .15s ease" }}
                                                        >
                                                            Read More
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            )
                                :
                                <div class="text-center">
                                    <div role="status">
                                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                        } */}

                            <div className="container mx-auto px-0 lg:px-24 ">
                                <div className="lg:pt-12 pt-6 w-full md:w-12/12 ">
                                    <div className="relative flex flex-col min-w-0 break-words bg-blue-50 w-full mb-8 ">
                                        <div className=" py-5 flex-auto">
                                            {events.length ? events.map((event, index) => (
                                                <div key={index} className="mb-8 border-b border-gray-200 ">
                                                    <div className="my-4">
                                                        <h2 className="text-2xl font-semibold mb-2 hover:text-blue-400">{event.headline}</h2>
                                                        <div className="text-gray-600 text-sm flex items-center mb-4">
                                                            <span className="mr-2">{event.date}</span>
                                                            <span>•</span>
                                                            <span className="ml-2 mr-2">{event.readTime} min read</span>
                                                            <span>•</span>
                                                            <Link to={`/events/${event._id}`} className="ml-2 text-blue-400 font-bold">Read more</Link>
                                                        </div>
                                                        {/* <p className="text-gray-700 mb-4">{post.excerpt}</p> */}

                                                    </div>
                                                </div>
                                            ))
                                                :
                                                <div class="text-center">
                                                    <div role="status">
                                                        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                        </svg>
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Events;