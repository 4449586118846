import React, { useEffect, useRef, useState } from 'react';
import './Newsletter.css'
import { faArrowLeftLong, faArrowRight, faArrowRightLong, faArrowRotateLeft, faDownload, faFileLines, faMagnifyingGlassMinus, faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import draft1 from '../../../assets/img/newsletter/wisdom_waves_v1_i1/1st day of session 23-24.png'
import draft2 from '../../../assets/img/newsletter/wisdom_waves_v1_i2/1.jpg'
import { Link } from 'react-router-dom';



const Newsletter = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Newsletter | Al-Maghrib';
    }, []);


    const letters = [
        {
            id: 2,
            name: 'Wisdom Waves',
            href: 'newsletter/Wisdom_Waves_v1_i2',
            imageSrc: draft2,
            imageAlt: "Wisdom_Waves_v1_i2",
            date: 'January - June 2024',
            volume: 1,
            issue: 2,
        },
        {
            id: 1,
            name: 'Wisdom Waves',
            href: 'newsletter/Wisdom_Waves_v1_i1',
            imageSrc: draft1,
            imageAlt: "Wisdom_Waves_v1_i1",
            date: 'July - December 2023',
            volume: 1,
            issue: 1,
        },
        // More products...
    ]

    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "45vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://images.squarespace-cdn.com/content/v1/5693b76fa128e6b30eb62bc7/1453221585127-1455H38WCLY460L108HK/vintage-%281920x1200%29-wallpaper-6%2Bcopy.jpg?format=2500w')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                            <div className="">
                                <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    Newsletter
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong>To the community of Al-Maghrib International School, Welcome to the newsletter section where you may find the most recent information and highlights from our monthly, half-yearly, and annual assessments.</strong>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">
                    <div className="w-full md:w-12/12 ">
                        <div className="relative flex flex-col min-w-0 break-words bg-blue-50 w-full mb-8  ">
                            {/* filter */}
                            <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
                                {/* <h2 className="text-2xl font-bold tracking-tight text-gray-900 text-center md:text-left">WISDOM WAVES</h2> */}

                                <h1 class="mb-4 mt-4 md:mt-0 text-xl md:text-3xl font-extrabold text-gray-900 dark:text-white "><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">Half Yearly </span>WISDOM WAVES</h1>
                                <p class="mb-4 font-normal text-gray-500 text-lg lg:text-xl dark:text-gray-400 ">Introducing our biannual newsletter, which provides you with the most recent information and highlights from each six-month period.</p>

                                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                                    {letters.map((letter) => (
                                        <Link to={`/newsletter/${letter.imageAlt}`} >
                                            <div key={letter.id} className="group relative">
                                                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                                                    <img
                                                        alt={letter.imageAlt}
                                                        src={letter.imageSrc}
                                                        className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                                                    />
                                                </div>
                                                <div className="mt-4 flex justify-between">
                                                    <div>
                                                        <h3 className="text-sm text-gray-700">
                                                            <a href={letter.href}>
                                                                <span aria-hidden="true" className="absolute inset-0" />
                                                                {letter.name}
                                                            </a>
                                                        </h3>
                                                        <p className="mt-1 text-sm text-gray-500">{letter.date}</p>
                                                    </div>
                                                    <p className="text-sm font-medium text-gray-900">Vol: {letter.volume}</p>
                                                    <p className="text-sm font-medium text-gray-900">Issue: {letter.issue}</p>
                                                </div>
                                            </div>
                                        </Link>

                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    );
}

export default Newsletter;
