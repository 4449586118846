import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Navbar from './components/shared/Navbar';
import Landing from "./components/views/Landing";
import Principal from "./components/views/About/Principal";
import About from './components/views/About/About';
import SingleFaculty from './components/views/About/SingleFaculty';
import Faculties from './components/views/About/Faculties';
import EYFS from './components/views/Academic/EYFS';
import Stage1 from './components/views/Academic/Stage1';
import Stage2 from './components/views/Academic/Stage2';
import AfterSchool from './components/views/Academic/AfterSchool';
import Curriculum from './components/views/Academic/Curriculum';
import Progress from './components/views/Academic/Progress';
import Academic from './components/views/Academic/Academic';
import SingleEvent from './components/views/Events/SingleEvent';
import Events from './components/views/Events/Events';
import Transport from './components/views/Facility/Transport';
import Residential from './components/views/Facility/Residential';
import Dining from './components/views/Facility/Dining';
import AdmissionNews from './components/views/Admission/AdmissionNews';
import AdmissionForm from './components/views/Admission/AdmissionForm';
import FooterNew from './components/shared/FooterNew';
import AcademicPolicy from './components/views/Academic/AcademicPolicy';
import AdmissionPolicy from './components/views/Admission/AdmissionPolicy';
import NotFound from './components/views/NotFound/NotFound';
import Vision from './components/views/About/Vision';
import Values from './components/views/About/Values';
import Core from './components/views/About/Core';
import CEO from './components/views/About/CEO';
import Management from './components/views/About/Management';
import Contact from './components/views/Contact/Contact';
import AdmissionProcess from './components/views/Admission/AdmissionProcess';
import Gallery from './components/views/Gallery/Gallery';
import Career from './components/views/Career/Career';
import VicePrincipal from './components/views/About/VicePrincipal';
import Footer from './components/shared/Footer';
import Navbar2 from './components/shared/Navbar2';
import Navbar3 from './components/shared/Navbar3';
import Hero from './components/views/Landing/Hero/Hero';
import Notice from './components/views/Notice/Notice';
import Newsletter from './components/views/Newsletter/Newsletter';
import WW_v1_i1 from './components/views/Newsletter/WW_v1_i1';
import WW_v1_i2 from './components/views/Newsletter/WW_v1_i2';




function App() {
  return (
   
      <HelmetProvider>
        <Router>
          {/* <Navbar  /> */}
          <Navbar2 />
          {/* <Navbar3 /> */}
          <Routes>
            {/* <Route exact path="/" element={<Landing />} /> */}
            <Route exact path="/" element={<Hero />} />

            <Route path='/about/principal' element={<Principal />} />
            <Route path='/about/ceo' element={<CEO />} />
            <Route path='/about/vice-principal' element={<VicePrincipal />} />
            {/* <Route path='/faculties/:id' element={<SingleFaculty />} /> */}
            {/* <Route path='/about/faculties' element={<Faculties />} /> */}
            <Route path='/about/vision' element={<Vision />} />
            <Route path='/about/values' element={<Values />} />
            <Route path='/about/management' element={<Management />} />
            <Route path='/about/core-academic-principles' element={<Core />} />
            {/* <Route path='/about' element={<About />} /> */}

            {/* <Route path='/academic/eyfs' element={<EYFS />} />
          <Route path='/academic/stage-1' element={<Stage1 />} />
          <Route path='/academic/stage-2' element={<Stage2 />} />
          <Route path='/academic/after-school' element={<AfterSchool />} /> */}
            <Route path='/academic/curriculum' element={<Curriculum />} />
            <Route path='/academic/progress' element={<Progress />} />
            <Route path='/academic/policies' element={<AcademicPolicy />} />
            {/* <Route path='/academic' element={<Academic />} /> */}

            <Route path='/events/:id' element={<SingleEvent />} />
            <Route path='/events' element={<Events />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/newsletter/Wisdom_Waves_v1_i2' element={<WW_v1_i2 />} />
            <Route path='/newsletter/Wisdom_Waves_v1_i1' element={<WW_v1_i1 />} />
            <Route path='/newsletter' element={<Newsletter />} />
            <Route path='/notices' element={<Notice />} />

            {/* <Route path='/facility/transport' element={<Transport />} />
          <Route path='/facility/residential' element={<Residential />} />
          <Route path='/facility/dining' element={<Dining />} /> */}

            {/* <Route path='/admission/policies' element={<AdmissionPolicy />} /> */}
            {/* <Route path='/admission/online-form' element={<AdmissionForm />} /> */}
            <Route path='/admission/news' element={<AdmissionNews />} />
            <Route path='/admission/process' element={<AdmissionProcess />} />

            <Route path='/careers' element={<Career />} />
            <Route path='/contact-us' element={<Contact />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
          {/* <Footer /> */}
          <FooterNew />
        </Router>
      </HelmetProvider>
    

  );
}

export default App;
