import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';

const Core = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Core Academic Principles | Al-Maghrib';
    }, []);
    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "45vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        backgroundImage: "url('https://img.freepik.com/free-photo/high-angle-laptop-desk-arrangement_23-2149386337.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=sph')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                    <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    Core Academic Principles
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong>Inquiry - Cambridge Curriculum - Civil Services</strong> <br />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                <div className="container mx-auto px-4 mt-5">
                    <div className="lg:pt-12 pt-6 w-full md:w-12/12">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                            <div className="px-4 py-5 flex-auto">
                                <div className="text-center">
                                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                                        <FontAwesomeIcon icon={faStar} size="lg" />
                                    </div>
                                    <h6 className="text-xl text-center font-semibold">Core Academic Principles</h6>
                                </div>
                                <p></p>
                                <div class="divide-y divide-slate-200 ">
                                    <div>
                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                            Inquiry Based Learning for both General education and Islamic Education
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                            Our educational programmes and activities are broadly based on the Cambridge
                                            Curriculum and AL-Maghrib own curriculum
                                        </p>
                                    </div>
                                    <div>
                                        <p class="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                                            Al-Maghrib syllabus will be mainly focusing on British Curriculum
                                            (Cambridge). However, our Students will be fully prepared for local job
                                            markets, civil services and public sectors as we offer other essential
                                            subjects e.g. Bengali language and literature and Bangladesh Studies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Core;