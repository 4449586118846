import React from 'react';
import advisor1 from '../../../../assets/img/board_members/advisor1.jpg'
import advisor2 from '../../../../assets/img/board_members/advisor2.jpg'
import advisor3 from '../../../../assets/img/board_members/advisor3.jpg'
import advisor4 from '../../../../assets/img/board_members/advisor4.png'
import principal from '../../../../assets/img/board_members/helal.png'
import ceo from '../../../../assets/img/board_members/ceo1.jpg'
import vice_principal from '../../../../assets/img/board_members/emdad.jpg'

const Board = () => {
    return (
        <div>
            <div>
                <div className="relative isolate px-3 pt-12 md:px-8">
                    {/* <div
                        className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#60a5fa] to-[#d1ffeb] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div> */}
                    <div className="mx-auto mb-5 lg:mb-24 sm:py-32 md:py-8 lg:py-12" >
                        <div className="container mx-auto px-1 md:px-4">
                            <div className="flex flex-wrap justify-center text-center mb-5">
                                <div className="w-full lg:w-6/12 px-0 md:px-4">
                                    <h2 className="sm:text-4xl text-3xl font-bold tracking-tight text-gray-900">
                                        Here are Our Advisors
                                    </h2>
                                    <p className="text-lg leading-relaxed m-4 text-gray-600">
                                        A team of experienced experts prepared to guide our academic journey.
                                        We are ready to discover their insights and fuel our success.
                                    </p>
                                </div>
                            </div>

                            {/* <div className="flex flex-wrap">
                                <div className="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="SHEIKH DR. MAHMUDUL HASAN AL-AZHARI"
                                            src={advisor1}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center w-full">
                                            <h5 className="font-bold">
                                                SHEIKH DR. MAHMUDUL HASAN AL-AZHARI
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Chief Advisor
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="DR. GOLAM QUADER CHY NOBEL"
                                            src={advisor2}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold ">
                                                DR. GOLAM QUADER CHY NOBEL
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Advisor
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="PROFESSOR KHANDAKAR KABIR UDDIN"
                                            src={advisor3}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold">
                                                PROFESSOR KHANDAKAR KABIR UDDIN
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Advisor
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="Al-USTAJ SHEIKH AFEEF FURQAN AL-MADANI"
                                            src={advisor4}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold ">
                                                Al-USTAJ SHEIKH AFEEF FURQAN AL-MADANI
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Advisor of Islamic Studies & Arabic Language
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div> */}
                            <div className="flex flex-wrap justify-around ">
                                <div className="w-full md:w-1/4 bg-white ">
                                    <img src={advisor1} alt="Advisor 1" className="mx-auto md:ml-0 rounded-full shadow-lg" style={{ maxWidth: "120px" }} />
                                    <div className="px-1 py-4">
                                        <h3 className="font-bold text-blue-400">SHEIKH DR. MAHMUDUL HASAN AL-AZHARI</h3>
                                        <p className="text-sm text-gray-500 font-semibold">CHIEF ADVISOR</p>
                                        <p className="text-sm text-gray-500">
                                            Khatib & Principal, Essex Jame Masjid & Islamic Center, South End, UK <br />
                                            Director: TV One UK <br />
                                            Former Professor: University of Chittagong, Bangladesh
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/4 bg-white ">
                                    <img src={advisor2} alt="Advisor 2" className="mx-auto md:ml-0 rounded-full shadow-lg" style={{ maxWidth: "120px" }} />
                                    <div className="px-1 py-4">
                                        <h3 className="font-bold text-blue-400">DR. GOLAM QUADER CHY NOBEL</h3>
                                        <p className="text-sm text-gray-500 font-semibold">ADVISOR</p>
                                        <p className="text-sm text-gray-500">
                                            MBBS, MSc in CVR (UK), PgDip derm (UK) <br />
                                            PgDip in Aesthetic Medicine (UK), CDM (UK), CCD <br />
                                            Director: Acocks Green Medical Center Birmingham, UK <br />
                                            Chairman: Rural Primary Health Initiative, Bangladesh
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/4 bg-white ">
                                    <img src={advisor3} alt="Advisor 3" className="mx-auto md:ml-0 rounded-full shadow-lg" style={{ maxWidth: "120px" }} />
                                    <div className="px-1 py-4">
                                        <h3 className="font-bold text-blue-400">PROFESSOR KHANDAKAR KABIR UDDIN</h3>
                                        <p className="text-sm text-gray-500 font-semibold">ADVISOR</p>
                                        <p className="text-sm text-gray-500">
                                            Chairman & Principal, Manat Foundation Islamic Center Birmingham, UK <br />
                                            Former Professor: Islamic University Bangladesh
                                        </p>
                                    </div>
                                </div>
                                <div className="w-full md:w-1/4 bg-white ">
                                    <img src={advisor4} alt="Advisor 4" className="mx-auto md:ml-0 rounded-full shadow-lg" style={{ maxWidth: "120px" }} />
                                    <div className="px-1 py-4">
                                        <h3 className="font-bold text-blue-400 ">AL-USTAJ SHEIKH AFEEF FURQAN AL-MADANI</h3>
                                        <p className="text-sm text-gray-500 font-semibold">ADVISOR OF ISLAMIC STUDIES & ARABIC LANGUAGE</p>
                                        <p className="text-sm text-gray-500">
                                            Ustajul Hadith & Secretary of Islamic Cultural Forum, Jamiah Darul Ma'arif Al-Islamiah, Chattogram.<br />
                                            Da'wah Representative, Ministry of Islamic Arrairs, Saudi Arabia
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container pt-24 mx-auto px-1 md:px-4">
                            <div className="flex flex-wrap justify-center text-center mb-12">
                                <div className="w-full lg:w-6/12 px-4 ">
                                    <h2 className="sm:text-4xl text-3xl font-bold tracking-tight text-gray-900">
                                        Board of Management
                                    </h2>
                                </div>
                            </div>

                            <div className="flex flex-wrap">
                                <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="..."
                                            src={principal}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold text-blue-400">
                                                M MOHIBBULLAH HELAL
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Chairman & Principal <br /> Al-Maghrib International School
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="..."
                                            src={ceo}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold text-blue-400">
                                                MD NEAZUL HOQUE
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                CEO & Academic Director <br /> Al-Maghrib International School
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/12 lg:w-4/12 lg:mb-0 mb-12 px-0 md:px-4">
                                    <div className="px-0 md:px-6">
                                        <img
                                            alt="..."
                                            src={vice_principal}
                                            className="shadow-lg rounded-full max-w-full mx-auto"
                                            style={{ maxWidth: "120px" }}
                                        />
                                        <div className="pt-6 text-center">
                                            <h5 className="font-bold text-blue-400">
                                                MOHAMMAD EMDAD ULLAH
                                            </h5>
                                            <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                                                Vice-Principal <br /> Al-Maghrib International School
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    {/* <div
                        className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Board;