import React, { useEffect } from 'react';

const NotFound = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Page Not Found | Al-Maghrib';
    }, []);
    return (
        <div>
            <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                style={{
                    minHeight: "100vh"
                }}>
                <div className="absolute top-0 w-full h-full bg-center bg-cover"
                    style={{
                        // backgroundImage: "url('https://media.istockphoto.com/id/1460985803/photo/hand-flipping-of-2023-to-2024-on-wooden-block-cube-for-preparation-new-year-change-and-start.jpg?s=612x612&w=0&k=20&c=29THUBIis7vYKYNIKEyms6xDXbJvIHYs3litHohd5xQ=')"
                    }}>
                    <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                </div>
                <div className="container relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                            <div className="">
                                <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                    <span className='text-red-400'>404</span>  | PAGE NOT FOUND
                                </h1>
                                <p className="mt-4 text-lg text-gray-300">
                                    <strong>
                                        The page you are looking for does not exist. It might have been moved or deleted.
                                    </strong> <br />
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotFound;