import React, { useEffect, useState } from 'react';
import school from '../../../assets/img/school/web logo@4x.png'
import Meta from '../../meta/Meta';

const Notice = () => {
    const pageDescription = "Stay informed with the latest announcements and upcoming news at Al-Maghrib International School. Explore our notices section for important updates, events, and news that shape our vibrant school community.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/notices";

    const storedData = sessionStorage.getItem('notices');
    const initialNotices = storedData ? JSON.parse(storedData) : [];
    const [notices, setNotices] = useState(initialNotices);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Notice Board | Al-Maghrib";

        if (!storedData) {
            fetch('https://al-maghrib-server.vercel.app/notice/all')
                .then(res => res.json())
                .then(data => {
                    const reversedArray = data.notice.reverse();
                    setNotices(reversedArray);
                    sessionStorage.setItem('notices', JSON.stringify(reversedArray));
                })
        }
    }, [])

    const handleDownload = (notice) => {
        // e.preventDefault();
        const anchor = document.createElement('a');
        anchor.href = notice.imageURL;
        anchor.target = '_blank';
        anchor.download = `Notice on ${notice.date}`;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };
    return (
        <>
            <Meta pageTitle={"Notice Board | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://images.unsplash.com/photo-1503551723145-6c040742065b-v2?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODB8fG5vdGljZSUyMGJvYXJkfGVufDB8fDB8fHww')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Notice Board
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>
                                            School Updates | Important news & announcements from Al-Maghrib International School!
                                        </strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">
                        <div className="py-6 grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4">
                            {notices.map((notice) => (
                                <div key={notice._id} className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-300">
                                    <div className="">
                                        <img
                                            src={notice?.imageURL || school}
                                            className="w-full h-32 md:h-48 object-cover rounded-t-lg"
                                            loading="lazy"
                                        />
                                        <div className="p-2 md:p-4">
                                            <p className="text-gray-600 mb-2 text-xs md:text-base">{notice.date}</p>

                                            <div className='flex justify-center md:justify-start'>
                                                <button
                                                    onClick={() => handleDownload(notice)}
                                                    className=" text-white px-2 md:px-5 py-2 md:py-2 rounded-md bg-blue-400 hover:bg-blue-500 focus:outline-none text-xs md:text-base"
                                                >
                                                    Download/View
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    </div>
                </section>



            </div>

        </>
    );
};

export default Notice;