import React from 'react';
import { useState, useEffect } from 'react';
import Vision from '../Vision/Vision';
import { Link } from 'react-router-dom';

const UpcomingEvents = () => {
    const storedData = sessionStorage.getItem('upcomingEvents');
    const initialUpcomingEvents = storedData ? JSON.parse(storedData) : [];
    const [allUpcomingEvents, setAllUpcomingEvents] = useState(initialUpcomingEvents);
    useEffect(() => {

        if (!storedData) {
            fetch(`https://al-maghrib-server.vercel.app/upcomingEvent/all`)
                .then(res => res.json())
                .then(data => {
                    setAllUpcomingEvents(data.upcomingEvents)
                    sessionStorage.setItem('upcomingEvents', JSON.stringify(data.upcomingEvents));

                })
        }
    }, []);
    return (
        <div>
            <div className="bg-white">
                <div className="mx-auto  sm:px-6 sm:py-32 md:py-12 ">
                    <div className="relative isolate overflow-hidden  pt-16  sm:px-16 md:pt-24 lg:flex lg:gap-x-20  lg:pt-0">
                        {/* <svg
                            viewBox="0 0 1024 1024"
                            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                            aria-hidden="true"
                        >
                            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                            <defs>
                                <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                    <stop stopColor="#75aed6" />
                                    <stop offset={1} stopColor="#359ee9" />
                                </radialGradient>
                            </defs>
                        </svg> */}
                        <div className="container mx-auto px-4">
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 border border-blue-200 bg-blue-50'>
                                <div className='ml-auto mr-auto flex justify-center my-4 p-3 py-5 md:py-0 '>
                                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto md:py-12 lg:text-left">
                                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                            Upcoming Events

                                        </h2>
                                        <div className="mt-5 flex items-center justify-center gap-x-6 lg:justify-start">

                                            <Link to="/events" className="text-sm font-semibold leading-6 text-gray-900">
                                                Explore the rich tapestry of our recent stories <span aria-hidden="true">→</span>
                                            </Link>
                                        </div>
                                        <div className={`flow-root  mx-auto  mt-5 lg:mt-5  py-5 ${allUpcomingEvents.length && 'h-60 overflow-y-scroll'}`}>
                                            <div className="-my-4 divide-y divide-gray-300 ">
                                                {
                                                    allUpcomingEvents.length ? allUpcomingEvents.map((event, index) => (
                                                        <div className="flex flex-col gap-2 py-1  sm:flex-row sm:items-center">
                                                            <p className="w-full md:w-28 text-lg font-normal text-gray-500 text-left  shrink-0">
                                                                {event.date}
                                                            </p>
                                                            <h3 className="text-lg  text-gray-900 text-left">
                                                                {event.headline}
                                                            </h3>
                                                        </div>

                                                    ))
                                                        :
                                                        <h3 className="text-lg text-gray-900 text-center my-5">
                                                            There are no events coming up right now.
                                                        </h3>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className=' flex justify-center py-5 md:py-0 p-3 md:p-0 bg-blue-400'>
                                    <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto md:py-12 lg:text-left">
                                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                            Our Vision

                                        </h2>
                                        <p className="mt-6 text-lg leading-8 text-white text-justify">
                                            To be the best Premium and Transparent Islamic school that represents a holistic and caring environment that
                                            nurtures excellence in academic and moral education. We aim to create successful learners, Responsible Muslim
                                            Citizens, Confident Individuals and accountable leaders that will serve humanity. We also believe in character
                                            before brilliance and every child is unique and every child is capable of learning.

                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpcomingEvents;