import React, { useEffect } from 'react';
import principal_pic from '../../../assets/img/board_members/helal.png';

const Principal = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "Principal | Al-Maghrib";
    }, []);
    return (
        <>
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "55vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/premium-vector/modern-blue-abstract-background-vector-illustration-design-presentation-banner-cover-web-flyer-card-poster-wallpaper-texture-slide-magazine-powerpoint_181182-19659.jpg?w=900')"

                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>

                    <div className="container  relative mx-auto">
                    <div className="items-center flex flex-wrap">
                        <div className="mt-5 flex flex-col items-center md:flex-row  px-12" >
                            <div className="w-full md:w-6/12 lg:w-6/12  md:mr-auto" >
                                <div className="text-center ">
                                    <h1 className="text-white font-bold text-4xl md:text-6xl lg:text-7xl  ">
                                    Chairman & Principal's Message
                                    </h1>
                                    <p className="mt-4 mb-4 text-xl md:text-2xl text-gray-300 border border-blue-400 p-1 md:p-4">
                                        <strong>M Mohibbullah Helal</strong> <br />

                                    </p>
                                </div>
                            </div>
                            <div className="w-full md:w-6/12 lg:w-6/12 px-4 ml-auto md:block flex justify-center" >
                                <div className="w-full md:w-3/4 p-1 md:p-2">
                                    <img
                                        alt="gallery"
                                        class="block h-full w-full rounded-lg object-cover object-center"
                                        src={principal_pic}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <section className=" pt-20 pb-20 bg-blue-50 -mt-28">
                    <div className="mt-24 flex justify-center">
                        <div className="container px-4 md:px-16 mt-5">
                            <div className='text-justify'>
                                As the Humbled Principal of Al-Maghrib International School, I extend a warm welcome to our dynamic community of learners, educators, and parents. Our international school stands as a beacon of academic excellence and Islamic values, offering a unique blend of diverse cultures and unwavering commitment to nurturing well-rounded individuals.
                                <br /><br />
                                At Al-Maghrib, we believe in providing a holistic education that transcends textbooks and classrooms. Our dedicated faculty is passionate about not only imparting knowledge but also instilling the principles of Islam, compassion, integrity, and global citizenship. Through a rigorous academic curriculum and a strong emphasis on Islamic character development, we strive to prepare our students for success in an ever-changing world.
                                <br /><br />
                                Our commitment to Islamic values is woven into the fabric of daily life at Al-Maghrib. We aim to create an environment where students not only excel academically but also grow spiritually, fostering a deep connection to Allah. Our Islamic studies curriculum is designed to instill a sense of moral responsibility and a profound understanding of the teachings of Islam.
                                <br /><br />
                                As an international school, we celebrate diversity and embrace a global perspective that are aligned with Islamic Values. Our students engage in a rich tapestry of cultural experiences, broadening their horizons and preparing them to navigate an interconnected world.
                                <br /><br />
                                I invite you to explore our website and discover the vibrant tapestry that is Al-Maghrib. Whether you are a prospective student, a current parent, or a visitor seeking insights into our educational philosophy, we hope you find the information enlightening and inspiring.
                                <br /><br />
                                Thank you for considering Al-Maghrib International School as a partner in your child's educational journey. Together, let us foster a community where minds are enlightened, hearts are kindled, and futures are shaped with purpose.
                                <br /><br />
                                Best regards, <br />
                                <b>M Mohibbullah Helal</b> <br />
                                Chairman & Principal

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </>
    );
};

export default Principal;