import React, { useEffect } from 'react';
import Meta from '../../meta/Meta';

const Values = () => {
    const pageDescription = "Get a better understanding of Al-Maghrib International School.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/about/values";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Values | Al-Maghrib';
    }, []);
    const values = ["Tawheed & God Consciousness", "Courage", "Charitable", "Patience",
        "Consultation", "Hard Work", "Respect", "Excellence", "Discipline", "Humility",
        "Gratitude", "Resistance to Evil", "Self-Control", "Unity in Diversity", "Dependency", "Honesty"]
    return (
        <>
            <Meta pageTitle={"Values | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://img.freepik.com/premium-photo/core-values-words-wooden-blocks-3d-illustration_764664-24005.jpg?size=626&ext=jpg&ga=GA1.1.162958315.1697884611&semt=ais')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Values of Al-Maghrib
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>Guiding Principles - Elevating Lives - Courage to Unity</strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-0 md:px-4 mt-5">
                        <div className="flex flex-wrap justify-center content-center  " >
                            {
                                values.map((value, index) => (
                                    <div key={index} className="w-full md:mt-4  md:w-3/12 lg:w-3/12 lg:mb-0 mb-12 px-4 flex justify-center">
                                        <div class="max-w-sm w-full flex justify-center items-center p-6 bg-white border border-blue-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                            <h5 class="mb-2 text-2xl text-center font-bold tracking-tight text-blue-400 dark:text-white">
                                                {value}
                                            </h5>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default Values;