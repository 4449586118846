import React, { useEffect } from 'react';
import admission_pic_1 from '../../../assets/img/admission/admission_1.jpg'
import admission_pic_2 from '../../../assets/img/admission/admission_2.jpg'
import { Link } from 'react-router-dom';
import Meta from '../../meta/Meta';

const AdmissionNews = () => {
    const pageDescription = "Check out the latest news on school admission.";
    const pageImage = "https://i.ibb.co/gwkTRWk/new-School.png"
    const pageUrl = "https://almaghribschool.com/admission/news";
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = 'Admission | Al-Maghrib';
    }, []);
    return (
        <>
            <Meta pageTitle={"Admission | Al-Maghrib"} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                    style={{
                        minHeight: "45vh"
                    }}>
                    <div className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage: "url('https://media.istockphoto.com/id/1460985803/photo/hand-flipping-of-2023-to-2024-on-wooden-block-cube-for-preparation-new-year-change-and-start.jpg?s=612x612&w=0&k=20&c=29THUBIis7vYKYNIKEyms6xDXbJvIHYs3litHohd5xQ=')"
                        }}>
                        <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                    </div>
                    <div className="container relative mx-auto">
                        <div className="items-center flex flex-wrap">
                            <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                <div className="">
                                    <h1 className="text-white font-semibold text-4xl md:text-5xl">
                                        Admission is Running for Session 2023 - 2024
                                    </h1>
                                    <p className="mt-4 text-lg text-gray-300">
                                        <strong>Visit Our Campus for More Information</strong> <br />
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="pt-20 pb-20 bg-blue-50 -mt-24">
                    <div className="container mx-auto px-4 mt-5">


                        <div className="flex flex-wrap content-center justify-center " >
                            <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 " >
                                <div className="py-6 px-0 md:px-6 flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src={admission_pic_1} alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Admission open at Al-Maghrib International School
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                Session 2023-2024 <br />
                                                For Classes: <br />
                                                EYFS- Playgroup, Nursery, & Reception (KG) <br />
                                                Year 01-Year 04 <br />
                                                Full time (Hifz Department) with Cambridge curriculum. <br />
                                                Admission forms are available for collection within office hours. <br />
                                                Saturday (02.00 PM- 05.00 PM) <br />
                                                Sunday-Thursday (09.00 AM- 04.00 PM) <br />

                                            </p>
                                            {/* <div className="mt-6">
                                            <Link to="/admission/online-form">
                                                <button
                                                    className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="button"
                                                    style={{ transition: "all .15s ease" }}
                                                >
                                                    Enroll Online
                                                </button>
                                            </Link>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full md:w-6/12 lg:w-6/12 lg:mb-0 mb-12 " >
                                <div className="py-6 px-0 md:px-6 flex justify-center">
                                    <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <img class="rounded-t-lg" src={admission_pic_2} alt="" />
                                        <div class="p-5">
                                            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                                                Playgroup Special Batch Admission for January to June 2024
                                            </h5>
                                            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
                                                Playgroup Special Batch Admission for January to June 2024 Session is now going on. Enroll Your Little One and Watch Them Grow!
                                                Integrated Curriculum (Islamic Studies and Cambridge curriculum)
                                            </p>
                                            {/* <div className="mt-6">
                                            <Link to="/admission/online-form">
                                                <button
                                                    className="bg-blue-400 text-white active:bg-blue-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                                    type="button"
                                                    style={{ transition: "all .15s ease" }}
                                                >
                                                    Enroll Online
                                                </button>
                                            </Link>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </section>
            </div>
        </>
    );
};

export default AdmissionNews;