import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';


const Meta = props => {
    const { pageTitle, pageDescription, pageImage, pageUrl } = props;
    document.title = pageTitle;
    return (
        
            <Helmet>
                {/* Common meta tags */}
                <title>{pageTitle}</title>
                <meta name="description" content={pageDescription} />

                {/* Open Graph meta tags for Facebook and other platforms */}
                <meta property="og:title" content={pageTitle} />
                <meta property="og:description" content={pageDescription} />
                <meta property="og:image" content={pageImage} />
                <meta property="og:url" content={pageUrl} />
                <meta property="og:type" content="website" />

                {/* Twitter Card meta tags */}
                <meta name="twitter:card" content="summary_large_image" />
                {/* <meta name="twitter:site" content={twitterUsername} /> */}
                <meta name="twitter:title" content={pageTitle} />
                <meta name="twitter:description" content={pageDescription} />
                <meta name="twitter:image" content={pageImage} />

                {/* Additional meta tags for various platforms */}
                <meta property="al:web:url" content={pageUrl} />
            </Helmet>
       
    );
};

Meta.propTypes = {
    pageTitle: PropTypes.string.isRequired,
    pageDescription: PropTypes.string.isRequired,
    pageImage: PropTypes.string.isRequired,
    pageUrl: PropTypes.string.isRequired,
    // twitterUsername: PropTypes.string, // Make this required if it's needed for your app
};

export default Meta;