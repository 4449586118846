import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import school from '../../../assets/img/school/newSchool.png'
import Meta from '../../meta/Meta';

const SingleEvent = () => {
    const { id } = useParams();
    const pageDescription = "Experience the vibrancy of regular events at Al-Maghrib International School. Explore our students' diverse activities, both on and off-campus, where they learn, unwind, and build lasting memories. Discover a dynamic blend of education and enjoyment, fostering holistic growth and enriching experiences.";
    let pageImage = "https://i.ibb.co/gwkTRWk/new-School.png";
    const pageUrl = `https://almaghribschool.com/events/${id}`;
    let title = "News and Events | Al-Maghrib";

    const [singleEvent, setSingleEvent] = useState({});
    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = "News and Events | Al-Maghrib";


        fetch(`https://al-maghrib-server.vercel.app/magazine/single-magazine/${id}`)
            .then(res => res.json())
            .then(data => {
                setSingleEvent(data.singleMagazine);
                document.title = `${data.singleMagazine.headline} | Al-Maghrib`;
                pageImage = `${data.singleMagazine.imagesInfo[0]?.url}`
            })


    }, [id])

    title = `${singleEvent.headline ? singleEvent?.headline : "News and Events"} | Al-Maghrib`


    const formattedDescription1 = singleEvent?.description1?.split('\n').map((line, index) => (
        <p key={index}>{line}</p>
    ));
    const finalDescription1 = formattedDescription1?.reduce((prev, curr) => [prev, <br />, curr]);

    const formattedDescription2 = singleEvent?.description2?.split('\n').map((line, index) => (
        <p key={index}>{line}</p>
    ));
    const finalDescription2 = formattedDescription2?.reduce((prev, curr) => [prev, <br />, curr]);


    return (
        <>
            <Meta pageTitle={title} pageDescription={pageDescription} pageImage={pageImage} pageUrl={pageUrl} />
            <div>
                <div>
                    <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
                        style={{
                            minHeight: "45vh"
                        }}>
                        <div className="absolute top-0 w-full h-full bg-center bg-cover"
                            style={{
                                backgroundImage: `url(${singleEvent?.imagesInfo && singleEvent.imagesInfo[0]?.url || school})`
                            }}>
                            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
                        </div>
                        <div className="container relative mx-auto">
                            <div className="items-center flex flex-wrap">
                                <div className="w-full px-2 md:px-4 ml-auto mr-auto text-center">
                                    <div className="">
                                        <h2 className="text-white font-semibold text-3xl mb-2">{singleEvent.headline}</h2>
                                        <div className="text-gray-300 mb-4">
                                            {singleEvent.date} &middot; {singleEvent.readTime} min read
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <section className="pt-20 pb-20 bg-white -mt-24">
                    <div className="container mx-auto px-4 mt-5">

                        <div className="lg:pt-12 pt-6 w-full md:w-12/12 text">
                            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 ">

                                <div className="px-0 md:px-4  py-5 flex-auto">
                                    <p className="mb-8 ">{finalDescription1 && finalDescription1}</p>
                                    <div className="grid grid-cols-2 gap-4 mb-8">
                                        {singleEvent && singleEvent.imagesInfo && singleEvent.imagesInfo.map((image, index) => (
                                            <img
                                                key={index}
                                                src={image.url}
                                                alt={image.url}
                                                className="object-cover h-52 w-full"
                                                loading='lazy'
                                            />
                                        ))}

                                    </div>
                                    {singleEvent && singleEvent?.videosInfo && singleEvent?.videosInfo[0]?.value ?
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
                                            {singleEvent && singleEvent.videosInfo && singleEvent.videosInfo.map(video => (
                                              
                                                <div className=" ">
                                                    <iframe width="560" height="315" src={video.value} title="YouTube video player"
                                                        frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                        allowfullscreen
                                                        className="block w-full rounded-lg object-cover object-center"
                                                        loading="lazy"
                                                    ></iframe>
                                                </div>
                                               
                                            ))}
                                        </div>
                                        : ''
                                    }
                                    <p className="mb-8">{finalDescription2 && finalDescription2}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SingleEvent;